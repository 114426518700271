import React from 'react';
import cx from 'classnames';
import ReactPageScroller from 'react-page-scroller';
import s from './styles.module.scss'

const Gallery = ({ list }) => (
  <div className={s.root}>
    <ReactPageScroller>
      {list.map((v, i) => (
        <div className={s.slideWrap} key={i}>
          <img className={cx(s.slide, i%2 === 0 && s.sideRight, i%3 === 0 && s.sideCenter)} src={v} alt="" />
        </div>
      ))}
    </ReactPageScroller>
  </div>
);

export default Gallery;