import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import IconArrow from '@/assets/icons/arrow-link.svg';
// import cx from 'classnames';
import s from './styles.module.scss';

const Breadcrumbs = ({ items }) => {
  if (!items) return false;

  return (
    <div className={s.root}>
      <div className={s.container}>
        {items.map(( item, i) => {
          if ((i + 1) < items.length) {
            return (
              <Link className={s.item} to={item.path} key={i}>
                {item.title}
                <IconArrow className={s.icon} />
              </Link>
            )
          } else {
            return (
              <span className={s.item} key={i}>{item.title}</span>
            )
          }
        })}
      </div>
    </div>
  )
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact({
    title: PropTypes.string,
    path: PropTypes.string
  }))
};