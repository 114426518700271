import React from "react";
import canUseDOM from 'can-use-dom';
import { graphql } from 'gatsby'
import SEO from "@/components/seo";
import Layout from "@/components/layout";
import GalleryNav from "@/components/GalleryNav";
import Gallery from "@/components/Gallery";
import Breadcrumbs from "@/components/Breadcrumbs";
import NoSSR from '@/components/no-ssr';

const PAGE = {
  pageTitle: 'KOJI OTANI | Gallery',
  sectionTitle: '...',
  pageDescription: '...',
  headerColor: 'white',
}

const GalleryPage = ({ props }) => {
  const city = props.data.citiesJson;
  const breadcrumbs = [
    {
      title: city.region.name,
      path: `/gallery/${city.region.id}`
    },
    {
      title: city.country.name,
      path: `/gallery/${city.region.id}/${city.country.id}`
    },
    {
      title: city.name,
      path: `/gallery/${city.region.id}/${city.country.id}/${city.id}`
    }
  ];
  return (
    <Layout page={PAGE}>
      <SEO title={PAGE.pageTitle} />
      <GalleryNav />
      <Breadcrumbs items={breadcrumbs} />
      <NoSSR>
        <Gallery list={city.gallery} />
      </NoSSR>
    </Layout>
  );
};

const GalleryPageContainer = (props) => {
  if (
      props && props.data && props.data.citiesJson &&
      canUseDOM
    ) {
      return (
        <GalleryPage props={props} />
      );
    } else {
      return (
        <div>loading...</div>
      )
    };
};

export default GalleryPageContainer;

export const query = graphql`
  query($id: String) {
    citiesJson(id: { eq: $id } ) {
      id
      name
      gallery
      region {
        id
        name
      }
      country {
        id
        name
      }
      map {
        lat
        lng
      }
    }
  }
`