import React, { useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { Transition } from 'react-transition-group';
import IconArrowDown from '@/assets/icons/arrow-down.svg';
import s from './styles.module.scss';

const MOCK_DATA = [
  // {
  //   title: 'Europe',
  //   path: '/gallery/europe',
  //   items: [
  //     {
  //       title: 'France',
  //       description: 'Some text ...',
  //       bg: 'https://domainnamewire.com/wp-content/france-eiffel.jpg',
  //       path: '/france',
  //     },
  //     {
  //       title: 'Spain',
  //       description: 'Some text ...',
  //       bg: 'http://www.barcelona-tourist-guide.com/images/int/transport/metro/l9-sud-ticket-vending-machines/L550/l9-sud-ticket-vending-machines-barcelona-3.jpg',
  //       path: '/spain',
  //     },
  //     {
  //       title: 'Italia',
  //       description: 'Some text ...',
  //       bg: '',
  //       path: '/italia',
  //     },
  //     {
  //       title: 'Country',
  //       description: 'Some text ...',
  //       bg: '',
  //       path: '/country',
  //     },
  //   ]
  // },
  {
    title: 'Asia',
    path: '/gallery/asia',
  },
  // {
  //   title: 'North America',
  //   path: '/gallery/north-america',
  // },
  // {
  //   title: 'South America',
  //   path: '/gallery/south-america',
  // },
  // {
  //   title: 'Africa',
  //   path: '/gallery/africa',
  // },
];

const defaultStyle = {
  transition: `opacity ${300}ms ease-in-out`,
  opacity: 0,
  visibility: 'hidden',
}

const transitionStyles = {
  entering: { opacity: 1, visibility: 'visible' },
  entered:  { opacity: 1, visibility: 'visible' },
  exiting:  { opacity: 0, visibility: 'visible' },
  exited:  { opacity: 0, visibility: 'hidden' },
};

const GalleryNav = ({ title }) => {
  const [show, setShow] = useState(false);
  const [activeItem, setActiveItem] = useState(MOCK_DATA[0]);
  const [activeItemIndex, setActiveItemIndex] = useState(MOCK_DATA[0]);

  function handleMouseOver(index) {
    setShow(true);
    setActiveItemIndex(index);
    setActiveItem(MOCK_DATA[index]);
  }

  return (
    <div
      className={s.root}
      onMouseLeave={() => setShow(false)}
    >
      <div className={s.container}>
        {MOCK_DATA.map((item, i) => (
          <Link
            key={i}
            to={item.path}
            className={cx(s.item, (activeItemIndex === i && show) && s.itemActive )}
            onMouseEnter={() => handleMouseOver(i)}
          >
            {item.title}
            {MOCK_DATA[i].items && <IconArrowDown className={s.itemIcon} />}
          </Link>
        ))}
      </div>
      <Transition in={show} timeout={300}>
        {state => (
          <div style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
            <div className={s.subNav}>
              <div className={s.title}>{activeItem.title}</div>
              {activeItem.items &&
                <div className={s.previews}>
                  {activeItem.items.map((item, i) => (
                    <Link className={s.preview} key={i} to={item.path}>
                      <div
                        className={s.cover}
                        style={{ backgroundImage: `url(${item.bg})`}}
                      />
                      <div className={s.previewTitle}>{item.title}</div>
                      <div className={s.description}>{item.description}</div>
                    </Link>
                  ))}
                </div>
              }
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
};

export default GalleryNav;